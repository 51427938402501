<template>
  <div class="page">
    <div class="header-container">
      <div class="back-item">
        <router-link class="content link" :to="`/profile?id=${$store.state.auth.id}`">← {{ $t('General.Back') }}</router-link>
      </div>
    </div>
    <div class="header-space"></div>
    <div class="profile-info">
      <div>
        <label class="title">{{ user.preName }} {{ user.lastName }}</label>
        <b-spinner class="spin" v-show="notUpdated"></b-spinner>
        <span class="spin" v-show="notUpdated">Wird gespeichert...</span>
      </div>
      <div class="profile-form">
        <TextBox :placeholder="$t('Profile.EditPage.FirstName')" :value="user.preName" @input="user.preName = $event"></TextBox>
        <TextBox :placeholder="$t('Profile.EditPage.LastName')" :value="user.lastName" @input="user.lastName = $event"></TextBox>
        <TextBox :placeholder="$t('Profile.EditPage.Image')" :value="user.imageUrl" @input="user.imageUrl = $event"></TextBox>
        <TextBox :placeholder="$t('Profile.EditPage.Status')" :value="user.status" @input="user.status = $event"></TextBox>
        <b-form-checkbox @change="update" v-model="user.isPublic" switch style="width: 100%" class="heading">{{ $t('Profile.EditPage.Public') }}</b-form-checkbox>
        <div @click="update" class="button-container">
          <Button :text="$t('Profile.EditPage.Save')"></Button>
        </div>
      </div>
    </div>
    <div>
      <div @click="enableNotifications" class="button-container">
        <Button :text="$t('Profile.EditPage.EnableNotifications')" />
      </div>
    </div>
    <div class="spinnerWrapper">
    </div>
  </div>
</template>

<script>
import { getMessaging, getToken } from 'firebase/messaging'
import TextBox from '../components/TextBox.vue'
import Button from '../components/Button.vue'

export default {
  name: 'Profile',
  components: {
    TextBox, Button
  },
  data() {
    return {
      notUpdated: false,
      user: {
        preName: null,
        lastName: null,
        imageUrl: null,
        status: null,
        isPublic: false,
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const token = await localStorage.getItem('token');
      this.axios
        .get('user/get', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          this.user = r.data;
        })
    },
    async update() {
      const token = await localStorage.getItem('token');
      this.axios.patch('user/edit', this.user, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }).then(() => {
          this.notUpdated = false;
          this.toast('Gespeichert', 'Deine Änderungen wurden gespeichert.', 'success');
        }).catch((err) => this.handleError(err));
    },
    input() {
      this.notUpdated = true;
    },
    enableNotifications() {
      const messaging = getMessaging(this.$firebase);
      getToken(messaging, { vapidKey: 'BMTPjaNNb0ZQIlPe1aj4pZKqeTe0-JWXOQ2dOcK75byARH3WCMAfop8dWMXltnN2uNzvTQ10UOxnfYwoQMYhUck' })
        .then(async (fcmToken) => {
          const token = await localStorage.getItem('token');
          this.axios.patch('user/addFcmToken', { token: fcmToken }, {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            }).then(() => {
              this.toast('Gespeichert', 'Deine Änderungen wurden gespeichert.', 'success');
            }).catch((err) => this.handleError(err));
        });
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.profile-info {
  margin: 0;
  padding: 0;
}

.profile-form {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.button-container {
  width: fit-content;
}
</style>
