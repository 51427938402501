<template>
  <div class="textbox">
    <input :type="type || 'text'" 
           :placeholder="placeholder"
           v-model="inputVal"
           editable="true"
           contenteditable="true"
           :focusout="$emit('update')"
           required />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    type: String,
    placeholder: String
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/config';
@use '@/styles/colors';

$box-border-width: 2px;

.textbox {
  width: 100%;
  border-radius: config.$radius;

  & input {
    width: calc(100%);
    font-size: 1em;
    font-family: 'Oswald', sans-serif;
    
    padding-top: .6em;
    padding-bottom: .6em;
    padding-left: .8em;
    padding-right: .8em;
    outline: none;
    border: $box-border-width solid colors.$accent-1;
    border-radius: config.$radius;
    transition-duration: config.$transition;
    background-color: colors.$background;
    caret-color: colors.$text;
    color: colors.$text;

    &::placeholder {
      color: colors.$info;
      opacity: 1;
      user-select: none;
    }

    &:focus {
      border: $box-border-width solid colors.$accent-2;
    }
  }
}
</style>
